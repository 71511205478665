import { useEffect, useState } from 'react';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import breakpoints from 'assets/data/breakpoints';
import Icon from 'components/Icon';
import classNames from 'classnames';

const Pagination = ({ labels, className, pages = 1, onChangePage = null }) => {
    const totalPages = Array.from({ length: pages }, (_, i) => i + 1);
    const [currentPage, setCurrentPage] = useState(1);
    const { width } = useWindowDimensions();
    const maxVisibleCount = width < breakpoints.tablet ? 5 : 10;
    const nextVisibleCount = 4;

    useEffect(() => {
        setCurrentPage(1);
    }, [pages]);

    const handlePagePrev = () => {
        const page = currentPage - 1 === 0 ? 1 : currentPage - 1;
        setCurrentPage(page);
        onChangePage && onChangePage(page);
    };

    const handlePageNext = () => {
        const page = currentPage + 1 > pages ? pages : currentPage + 1;
        setCurrentPage(page);
        onChangePage && onChangePage(page);
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        onChangePage && onChangePage(page);
    };

    const showPaginationItem = (totalPages, pageIndex) => {
        // return true if total number of pages is less than or equal to max visible page number count
        if (totalPages.length <= maxVisibleCount) return true;
        // return true if
        // currentPage < maxVisibleCount: current page is less than max page number count (10) that can be shown
        // pageIndex <= maxVisibleCount: page index from total pages in iteration is less than equal to max visible page number count (10)
        if (currentPage < maxVisibleCount && pageIndex <= maxVisibleCount)
            return true;
        // return true if
        // currentPage >= maxVisibleCount: checks if current page is greater than equal to max count (e.g 12)
        // pageIndex > (currentPage + nextVisibleCount - maxVisibleCount): checks if page index from total pages in iteration is greater than starting range (e.g 6 = 12[current page] + 4[next visible] - 10[max visible])
        // pageIndex <= (currentPage + nextVisibleCount): checks if page index from total pages in iteration is less than equal to ending range (e.g 16 = 12[current page] + 4[next visible])
        // else return false if any of conditions false
        else
            return (
                currentPage >= maxVisibleCount &&
                pageIndex > currentPage + nextVisibleCount - maxVisibleCount &&
                pageIndex <= currentPage + nextVisibleCount
            );
    };

    return (
        <nav
            className={classNames('pagination', {
                [`${className}`]: className,
            })}
            aria-label="pagination"
        >
            <ul className="pagination__list">
                <li className="pagination__item">
                    <button
                        aria-label={'previous'}
                        onClick={handlePagePrev}
                        className={classNames('pagination__link', {
                            'pagination__link--disabled': currentPage === 1,
                        })}
                    >
                        <Icon
                            icon={'fa-solid fa-chevron-left'}
                            className={classNames({
                                [`pagination__prev`]: labels,
                            })}
                            aria-label="Previous Page"
                        />
                        {labels && (
                            <span className="pagination__label">Prev</span>
                        )}
                    </button>
                </li>
                {totalPages.map((item, index) => {
                    const pageIndex = index + 1; // adding 1 syncs actual page index to array index as array index starts from 0
                    if (showPaginationItem(totalPages, pageIndex)) {
                        return (
                            <li key={index} className="pagination__item">
                                <button
                                    aria-label={`page# ${pageIndex}`}
                                    onClick={() => handlePageClick(pageIndex)}
                                    className={classNames('pagination__link', {
                                        'pagination__link--active':
                                            currentPage === pageIndex,
                                    })}
                                >
                                    {item}
                                </button>
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
                <li className="pagination__item">
                    <button
                        key={Date.now()}
                        aria-label={'next'}
                        onClick={handlePageNext}
                        className={classNames('pagination__link', {
                            'pagination__link--disabled': currentPage === pages,
                        })}
                    >
                        {labels && (
                            <span className="pagination__label">Next</span>
                        )}
                        <Icon
                            icon={'fa-solid fa-chevron-right'}
                            className={classNames({
                                [`pagination__next`]: labels,
                            })}
                            aria-label="Next Page"
                        />
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
